.auth {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 69vh;
  margin: auto;
}
.auth-container {
  width: 380px;
  max-width: 80%;
  padding: 40px;
  margin: 1% 0;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
  transition: all 0.3s ease;
}

.tabs {
  display: flex;
  justify-content: space-between;
  position: relative;
  border-bottom: 7px solid #000;
  margin-bottom: 20px;
  padding-bottom: 5%;
}

.tab {
  flex: 1;
  text-align: center;
  padding: 10px 0;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;

  &.active {
    background-color: #000;
    color: white;
  }

  &:not(.active) {
    color: #000;
    background-color: white;
  }

  &.active::after {
    content: "";
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #000;
  }
}

.form-input {
  width: 94%;
  padding: 10px;
  margin: 1.5% 0;
  border: 2px solid #bbbbbb;
  border-radius: 10px;
  font-size: 14px;
  transition: border-color 0.3s ease;

  &:focus {
    border-color: #000;
  }
}

.code-boxes {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin: 20px 0;

  input {
    width: 100%;
    height: 40px;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    border: 2px solid #bbbbbb;
    border-radius: 13px;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;

    &:focus {
      border-color: #000;
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
    }

    &::placeholder {
      color: #ccc;
      font-weight: normal;
    }
  }
}

.verify-form {
  text-align: center;

  h2 {
    margin-bottom: 40px;
    letter-spacing: 3px;
  }
  p {
    font-weight: 500;
    color: #000;
  }
}
label {
  display: block;
}

input {
  display: block;
}

.form-button {
  width: 100%;
  padding: 12px;
  background-color: #000;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  margin-top: 15px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #000;
  }

  &:disabled {
    background-color: #aaa;
    cursor: not-allowed;
  }
}

.checkbox-container {
  margin-top: 10px;

  .checkbox-label {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #333;
    margin-bottom: 8px;

    a {
      color: #000;
      font-weight: 600;
      border-bottom: 1px solid #000;
    }

    input[type="checkbox"] {
      margin-right: 8px;
      width: 16px;
      height: 16px;
      cursor: pointer;
    }
  }
}

.form-text {
  margin-top: 15px;
  font-size: 14px;
  cursor: pointer;
  text-align: center;
  font-weight: bold;

  &:hover {
    text-decoration: underline;
  }
}
@media screen and (max-width: 780px) {
  .auth {
    height: 72vh;
  }
}
@media screen and (max-width: 600px) {
  .form-input {
    font-size: 12px;
    padding: 8px;
  }
  .auth {
    height: 75vh;
  }
  .form-button {
    font-size: 14px;
    padding: 10px;
  }

  .code-boxes input {
    height: 25px;
    font-size: 16px;
  }
}

@media screen and (min-width: 601px) and (max-width: 1024px) {
  .form-input {
    font-size: 13px;
  }

  .form-button {
    font-size: 15px;
  }
}
.forgot-password {
  text-align: center;
  margin-top: 30px;
}

.forgot-link {
  text-decoration: none;
  color: black;
  font-size: 16px;
  cursor: pointer;
  position: relative;
  background: transparent;
  border: none;
}

.forgot-link::after {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background-color: black;
  position: absolute;
  bottom: -3px;
  left: 0;
}
.back-button {
  background: none;
  border: none;
  font-size: 18px;
  font-weight: bold;
  transform: rotate(180deg);
  cursor: pointer;
}

.back-button:hover {
  color: #555;
}
.back-class {
  text-align: right;
}
.recovery_content {
  text-align: center;

  p {
    margin: 3.5% 0;
  }
  .form-button {
    margin-top: 3%;
  }
  .confirmPassword {
    margin-top: 3%;
  }
}
.error-message {
  color: red;
}
@media (min-width: 375px) and (max-width: 376px) {
  .register-margin {
    margin: 6% 0;
  }
}
@media (min-width: 540px) and (max-width: 541px) {
  .register-margin {
    margin: 6% 0;
  }
}
