.articles-box-card {
  // border: 1px solid #ddd;
  text-align: center;
  transition: all 0.3s ease-in-out;
  margin: 0 auto 5% auto;
  background: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  &:hover {
    background: #f9f9f9;
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15);
    transform: translateY(-4px);
  }

  &.expanded {
    background-color: #f9f9f9;
  }

  .box-icon {
    width: 30%;
    height: auto;
    margin-bottom: 9%;
  }

  .box-title {
    font-size: 0.9rem;
    font-weight: 900;
    margin: 6px 0;
    text-align: left;
    color: #000;
  }

  .box-content {
    font-size: 0.9rem;
    margin: 12px 0 0;
    text-align: left;
    color: #555;
  }

  .box-toggle {
    background: none;
    border: none;
    cursor: pointer;
    transition: transform 0.3s ease;
  }

  .box-toggle:hover {
    color: #000;
  }

  .arrow-icon {
    font-size: 35px;
    color: #000;
    transition: transform 0.6s ease-in-out;

    &:hover {
      color: #000;
    }

    &.rotated {
      transform: rotate(180deg);
    }
  }

  /* Responsive Design */
  @media (max-width: 1024px) {
    .box-title {
      font-size: 1rem;
    }

    .box-content {
      font-size: 0.9rem;
    }

    .arrow-icon {
      font-size: 30px;
    }
  }

  @media (max-width: 768px) {
    .box-content {
      font-size: 0.85rem;
    }

    .arrow-icon {
      font-size: 28px;
    }
  }

  @media (max-width: 480px) {
    display: block;
    .box-title {
      font-size: 0.9rem;
    }

    .box-content {
      font-size: 0.8rem;
    }

    .arrow-icon {
      font-size: 25px;
    }
  }
}

.box-image {
  width: 100%;
  height: 180px;
  object-fit: cover;
}

.box-details {
  padding: 0 16px 16px;
  text-align: left;
}

.box-title {
  font-size: 18px;
  font-weight: bold;
  margin: 0 0 8px;
}

.box-subtitle {
  font-size: 12px;
  color: #4c4c4c;
  margin: 0 0 8px;
}

.box-toggle {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 13px;
  font-weight: 600;
  margin: 0 0 12px;
}

.box-toggle:hover {
  text-decoration: underline;
}

.box-date {
  font-size: 12px;
  color: #3c3c3c;
  text-transform: uppercase;
}
.box-sub-details {
  border-bottom: #000 3px solid;
}
.imgg {
  height: 180px;
}
