.ActionPanel {
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  // margin: 1rem;
  justify-content: space-between;
  width: 100%;
  box-shadow: 5px 4px 9px 0px rgba(0, 0, 0, 0.35);
}

.btns {
  display: flex;
  width: 90%;
  justify-content: center;
}
.action-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80%;
}
.flip {
  text-align: center;
  user-select: none;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  p {
    text-transform: uppercase;
    font-size: 0.9rem;
    margin: 0.3rem 1rem;
  }
  border: 2px solid #000;
  border-radius: 15px;
  margin-bottom: 1rem;
}
.flip:hover {
  background-color: #e2e2e2;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.08);
}
.separ {
  p {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 0.9rem;
  }
}

.a-panel-title {
  text-transform: uppercase;
  text-align: center;
  margin: 1rem;
  font-size: 1.3rem;
  font-weight: 700;
}

.a-panel-text {
  margin: 1rem;
  width: 90%;
  text-align: center;
  font-size: 1rem;
  p {
    margin-bottom: 1rem;
  }
}

.a-img-item {
  -webkit-background-size: cover;
  background-size: cover;
  -webkit-background-position: center;
  background-position: center;
  -webkit-background-repeat: no-repeat;
  background-repeat: no-repeat;
  width: 200px;
  height: 200px;
  margin-top: 1rem;
  position: relative;
}
@media screen and (max-width: 768px) {
  .ActionPanel {
    margin: 1rem 0;
    height: 250px;
  }
  .a-panel-title {
    font-size: 1rem;
  }
  .a-panel-text {
    margin: 0;
    font-size: 0.9rem;
  }
  .a-img-item {
    padding-top: 0;
    width: 110px;
    height: 110px;
  }
  .flip {
    p {
      font-size: 0.9rem;
    }
  }
}
