.legal {
    min-height: calc(100vh - 5.4rem - 70px);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.lgl-cover {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 300px;
}

.legal-menu {
    width: 100%;
    background-color: #353535;
    position: relative;
}

.legal-links {
    justify-content: center;
    align-items: center;
    display: flex;
    width: 100%;
    background-color: #353535;

    p {
        text-transform: uppercase;
        font-weight: 600;
        font-size: .9rem;
        margin: .5rem 1rem;
        color: #fff;
    }

    // Hide on mobile
    @media (max-width: 768px) {
        display: none;
    }
}
.drp-bullet{
    transition: transform 0.3s ease;
    margin-right: .5rem;
}

.drp-bullet.open {
    transform: rotate(-90deg); 
  }


.dropdown-toggle {
    display: none;
    background-color: #353535;
    justify-content: center;
    align-items: center;
    color: #fff;
    border: none;
    padding: .6rem;
    font-size: .9rem;
    cursor: pointer;
    width: 100%;
    text-align: center;
    
    @media (max-width: 768px) {
        display: flex;
    }
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #353535;
    width: 100%;
    border: 1px solid #ccc;
    z-index: 10;
    display: none;
    flex-direction: column;
    @media (max-width: 768px) {
        display: flex;
    }
    a {
        padding: .7rem;
        text-decoration: none;
        color: #fff;
        font-size: .8rem;
        text-align: center;

        &:hover {
            background-color: #444;
        }
    }
}


.legal-content {
    width: 80%;
    margin: 2rem 0;
}

.lgcy-sub-title {
    margin-top: .8rem;
    margin-bottom: .2rem;
    font-weight: 600;
    font-size: 1rem;
}

.lgcy-text {
   text-align: justify;
}

.lgl-header{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #00000052;
    text-align: center;
    width: 100%;
    height: 100%;
    color: #fff;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 2rem;
    letter-spacing: 1rem;
}


@media (max-width: 768px) {
    .lgl-cover{
        height: 150px;
    }
    .lgl-header{
        font-size: 1.2rem;
        letter-spacing: .2rem;
    }
    .legal-content{
        width: 90%;
    }
    .lgcy-sub-title{
        font-size: .8rem;
    }
    .legal-content{
        margin: 1rem 0;
    }
    .lgcy-text {
   font-size: .8rem;
    }
}